header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(248, 239, 157);

    nav {
        background: rgba(0, 0, 0, 0.7);

        ul {
            position: relative;
            display: flex;
            justify-content: space-between;
            max-width: 1000px;
            margin: 20px auto;
            padding: 0 10px;

            li {
                list-style: none;

                a {
                    color: rgb(248, 239, 157);
                    font-size: 1.2em;
                    text-transform: capitalize;
                    text-decoration: none;
                    transition: 0.4s;

                    &:hover {
                        color: rgb(145, 101, 43);
                    }

                }
            }
        }
    }

    .container-logo {
        display: flex;
        margin: 0 auto;

        @media screen and (max-width: 400px) {
            width: 100%;
        }

        .logo {
            height: 300px;
            margin: 30px 15px;
            pointer-events: none;

            @media screen and (max-width: 541px) {
                height: 240px;
            }

            @media screen and (max-width: 400px) {
                height: 200px;
            }

            @media screen and (max-width: 340px) {
                height: 150px;
            }
        }

    }


}