footer {
    background: rgba(0, 0, 0, 0.7);
    padding-bottom: 15px;
    border-top: 1px solid rgb(248, 239, 157);

    .footer-container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .footer-left {
            display: flex;
            flex-direction: column;
            height: 200px;
            width: 65%;

            a {
                color: rgb(248, 239, 157);
                height: 48px;
                text-decoration: none;
                transition: 0.4s;

                &:hover {
                    color: rgb(145, 101, 43);
                }
            }
        }

        .footer-right {
            display: flex;
            flex-direction: column;
            height: 200px;
            width: 35%;


            a {
                color: rgb(248, 239, 157);
                height: 48px;
                text-decoration: none;
                transition: 0.4s;

                &:hover {
                    color: rgb(145, 101, 43);
                }
            }
        }

        h3 {
            text-transform: capitalize;
            align-items: start;

        }

        @media screen and (max-width: 1050px) {
            margin: 0 auto;
            width: 98%;
        }

        @media screen and (max-width: 485px) {
            height: 230px;
        }


        @media screen and (max-width: 460px) {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            height: 310px;

            .footer-left {
                width: 90%;
            }

            .footer-right {
                width: 90%;
            }
        }
    }

    .footer-bottom {
        margin-top: 50px;
        text-align: center;
    }
}