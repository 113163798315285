.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    overflow: hidden;
    z-index: 9999;
}

.loader__spinner {
    border: 8px solid rgb(145, 101, 43);
    border-top: 8px solid rgb(248, 239, 157);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: auto;
    background-image: linear-gradient(to bottom, rgb(145, 101, 43), rgb(248, 239, 157));
}

.loader__progress {
    position: absolute;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}