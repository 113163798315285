.section-mentions-legales {
    padding: 10px;
    max-width: 1000px;

    h1,
    h2,
    p {
        margin: 15px auto;
    }

    h2 {
        margin-top: 50px;
    }

    p {
        text-align: justify;
    }
}