@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: -apple-system, BlinkMacSystemFont, poppins, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('../assets/img/atom.webp') center/cover fixed;
    color: rgb(248, 239, 157);
    overflow-x: hidden;
    transition: 0.4s;
    margin: 0;

    .container-header,
    section,
    .footer-container {
        margin: 0 auto;
    }

    &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: -1;

    }
}

h1,
h2 {
    text-align: center;
}

h1 {
    font-size: 2em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}