.container-services {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 20px;

    .container-card {
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
        margin: 0 auto;

        .card {
            width: 400px;
            margin: 20px auto;
            height: 750px;
            border: 1px solid rgb(248, 239, 157);


            @media screen and (max-width: 430px) {
                height: 860px;
            }

            @media screen and (max-width: 400px) {
                height: 900px;
            }

            @media screen and (max-width: 352px) {
                height: 980px;
            }

            @media screen and (max-width: 328px) {
                height: 1080px;
            }

            @media screen and (max-width: 306px) {
                height: 1180px;
            }

            .img-1 {
                width: 100%;
                height: 40%;
                background: url(../../assets/img/services0.webp) center/cover;
            }

            .img-2 {
                width: 100%;
                height: 40%;
                background: url(../../assets/img/services1.webp) center/cover;
            }

            .img-3 {
                width: 100%;
                height: 40%;
                background: url(../../assets/img/services2.webp) center/cover;
            }

            .img-4 {
                width: 100%;
                height: 40%;
                background: url(../../assets/img/services3.webp) center/cover;
            }

            @media screen and (max-width: 430px) {
                width: 90%;
            }

            p {
                padding: 10px;
                text-align: justify;
            }
        }
    }
}