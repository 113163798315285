.section-notfound {
    padding-top: 1px;
    padding-bottom: 20px;

    p {
        text-align: center;
    }

    a {
        display: flex;
        justify-content: center;
        text-decoration: none;

        button {
            font-family: -apple-system, BlinkMacSystemFont, poppins, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
            cursor: pointer;
            width: 120px;
            height: 40px;
            background: white;
            border-radius: 50px;
            border: none;
            font-size: 1.1em;
            box-shadow: 3px 3px 10px rgb(145, 101, 43);
            background: linear-gradient(90deg, rgb(145, 101, 43)20%, rgb(248, 239, 157), rgb(145, 101, 43)80%);
            transition: 0.4s;
            margin: 20px;

            &:hover {
                transform: translateX(4px);
                filter: brightness(1.5);
            }
        }
    }
}