.cgv {
    padding: 10px;
    max-width: 1000px;

    a {
        color: rgb(248, 239, 157);
        font-size: 1.2em;
        text-decoration: none;
        transition: 0.4s;

        &:hover {
            color: rgb(145, 101, 43);
        }
    }

    h1,
    h2,
    p {
        margin: 15px auto;
    }

    h2 {
        margin-top: 50px;
    }

    ul,
    p {
        text-align: justify;
    }
}