.body {
    display: flex;
    flex-direction: column;
}

.container-home {
    display: flex;
    padding-bottom: 20px;

    .container {
        margin: 0 auto;
        max-width: 1000px;

        .text {
            display: flex;
            flex-direction: column;
            width: 100%;

            p {
                font-size: 1.17em;
                text-align: justify;
            }

            @media screen and (max-width: 1050px) {
                margin: 0 auto;
                width: 90%;
            }
        }
    }
}