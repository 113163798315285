form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    input,
    textarea {
        font-family: -apple-system, BlinkMacSystemFont, poppins, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        margin: 10px;
        width: 500px;
        height: 50px;
        background: none;
        color: rgb(248, 239, 157);
        font-size: 1.5em;
        border: none;
        border-bottom: 1px solid rgb(248, 239, 157);

        &:focus {
            outline: none;
        }

        @media screen and (max-width: 542px) {
            width: 300px;
        }

        @media screen and (max-width: 320px) {
            width: 280px;
        }
    }

    textarea {
        height: 110px;
        min-width: 500px;
        max-width: 500px;
        min-height: 50px;

        @media screen and (max-width: 542px) {
            max-width: 300px;
            min-width: 300px;
        }

        @media screen and (max-width: 320px) {
            max-width: 280px;
            min-width: 280px;
        }
    }

    .checkbox {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        margin-top: 30px;
        border: 2px solid rgb(248, 239, 157);
        border-radius: 4px;
        outline: none;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        background-color: rgb(145, 101, 43);
        border-color: rgb(248, 239, 157);
    }

    input[type="checkbox"]:checked::before {
        content: "\2713";
        display: block;
        color: #fff;
        font-size: 35px;
        line-height: 1;
        text-align: center;
        text-shadow: 1px 1px #666;
        font-weight: bold;
    }

    button {
        font-family: -apple-system, BlinkMacSystemFont, poppins, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: white;
        border-radius: 50px;
        border: none;
        font-size: 1.1em;
        box-shadow: 3px 3px 10px rgb(145, 101, 43);
        background: linear-gradient(90deg, rgb(145, 101, 43)20%, rgb(248, 239, 157), rgb(145, 101, 43)80%);
        transition: 0.4s;

        &:hover {
            transform: translateX(4px);
            filter: brightness(1.5);
        }
    }
}